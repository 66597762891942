// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase";


const firebaseConfig = {
  apiKey: "AIzaSyDwTOEvW_y0rexe1hJbmLCzvyH8hk7bt5A",
  authDomain: "whatsapp-colleegeek.firebaseapp.com",
  projectId: "whatsapp-colleegeek",
  storageBucket: "whatsapp-colleegeek.appspot.com",
  messagingSenderId: "274498036972",
  appId: "1:274498036972:web:d15aec404c8c73afb3b1c9",
  measurementId: "G-7H3ZP2ZQVG"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export {auth, provider};
export default db;