import React, { useEffect, useState } from 'react';
import { Avatar, IconButton } from "@material-ui/core";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchOutlined from '@material-ui/icons/Search';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import MicIcon from '@material-ui/icons/Mic';
import './Chat.css';
import { useParams } from "react-router-dom";
import db from './firebase';
import { useStateValue } from './StateProvider';
import firebase from "firebase";

const Chat = () => {

	const [input, setInput] = useState("");
	const [seed, setSeed] = useState("");
	const { roomId } = useParams();
	const [roomName,setRoomName] = useState("");
	const [messages,setMessages] = useState([]);
	const [{user},dispatch] = useStateValue();

	useEffect(() => {
		if(roomId){
			db.collection('rooms')
				.doc(roomId)
				.onSnapshot((snapshot) =>  setRoomName(snapshot.data().name));

			db.collection('rooms')
				.doc(roomId)
				.collection('messages')
				.orderBy('timestamp')
				.onSnapshot((snapshot) => setMessages(snapshot.docs.map((doc) => doc.data()))
					);
		}
	}, [roomId]);

	useEffect(() => {
		setSeed(Math.floor(Math.random() * 5000));
	}, [roomId]);

	const sendMessage = (e) => {
		e.preventDefault();
		if(!input || input.length === 0){}
		else{
			console.log("You typed >>> ",input);

			// server time is going to be always same for any country user belongs
			db.collection('rooms')
				.doc(roomId)
				.collection('messages')
				.add({
					message: input,
					name: user.displayName,
					timestamp: firebase.firestore.FieldValue.serverTimestamp(),
				});

			setInput("");
		}
	}

  return (
  	<div className="chat">
  		<div className="chat__header">
  			<Avatar src={`https://avatars.dicebear.com/api/male/${seed}.svg`} />
  			<div className="chat__headerInfo">
  				<h3>{roomName}</h3>
  				<p>
  					Last seen at{" "}
  					{messages[messages.length - 1]?.
  						timestamp?new Date(messages[messages.length - 1]?.
  						timestamp.toDate()).toUTCString():" "}
  					
  				</p>
  			</div>
  			<div className="chat__headerRight">
  				<IconButton>
  					<SearchOutlined />
  				</IconButton>
  				<IconButton>
  					<AttachFileIcon />
  				</IconButton>
  				<IconButton>
  					<MoreVertIcon />
  				</IconButton>
  			</div>
  		</div>
  		<div className="chat__body">
  			{messages.map((message) =>(
  				// check if sender and reciver is same, if no than add chat__reciever class
  				// it is not good practice in production because two person have same name in
  				// that case we have to use id for this. here it is only for functional perpose 
				<p className={`chat__message ${message.name === user.displayName && 'chat__reciever'}`}>
	  				<span className="chat__name">{message.name}</span>
	  				{message.message}
	  				<span className="chat__timestamp"><br/>
	  					{new Date(message.timestamp?.toDate()).toUTCString()}
	  				</span>
	  			</p>
  			))}
  		</div>
  		<div className="chat__footer">
  			<InsertEmoticonIcon />
  			<form>
  				<input value={input} onChange={e => setInput(e.target.value)} placeholder="Type a message" type="text" />
  				<button onClick={sendMessage} type="submit">send a message</button>
  			</form>
  			<MicIcon />
  		</div>	
  	</div>
  	);
}

export default Chat